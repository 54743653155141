import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import React, { useEffect, useState, Suspense } from "react";
import Layout from "./layout/layout";
import Dashboard from "./Pages/Dashboard/Dashboard";
// import MainPage from "./components/MainPage/MainPage";
import Signin from "./components/Signin/Signin";
import Signup from "./components/Signup/Signup";

import Main from "./Pages/Content_Libraries/Main";
import ContentVideo from "./Pages/Content_Libraries/ContentVideo";
import ContentLive from "./Pages/Content_Libraries/ContentLive";
import ContentAudio from "./Pages/Content_Libraries/ContentAudio";
import ContentSeries from "./Pages/Content_Libraries/ContentSeries";
import Media_Library from "./Pages/Content_Libraries/Media_Library";
import View_Media_Library from "./Pages/Content_Libraries/View_Media_Library";

import Allvideo from "./Pages/Video/Video_Management/Allvideo";
import Videoforapproval from "./Pages/Video/Video_Management/Videoforapproval";
import Videochannelpartner from "./Pages/Video/Video_Management/Videochannelpartner";
import Videocontentpartner from "./Pages/Video/Video_Management/Videocontentpartner";
import Mastervideolist from "./Pages/Video/Video_Management/Mastervideolist";
import Videoschedule from "./Pages/Video/Video_Management/Videoschedule";
import AddVideoschedule from "./Pages/Video/Video_Management/AddVideoschedule";
import EditVideoschedule from "./Pages/Video/Video_Management/EditVideoschedule";
import Manageschedule from "./Pages/Video/Video_Management/Manageschedule";
import Videopartner from "./Pages/Video/Video_Management/Videopartner";
import Managevideocategories from "./Pages/Video/Video_Management/Managevideocategories";
import AddManagevideocategories from "./Pages/Video/Video_Management/AddManagevideocategories";
import EditManagevideocategories from "./Pages/Video/Video_Management/EditManagevideocategories";
import Addnewvideo from "./Pages/Video/Video_Management/Addnewvideo";
import EditVideotag from "./Pages/Video/Video_Management/EditVideotag";

import AddNewLiveStream from "./Pages/Live Stream/Management_Live_Stream/AddNewLiveStream";
import AllLiveStream from "./Pages/Live Stream/Management_Live_Stream/AllLiveStream";
import LiveStreamApproval from "./Pages/Live Stream/Management_Live_Stream/LiveStreamApproval";
import Livecontentpartner from "./Pages/Live Stream/Management_Live_Stream/Livecontentpartner";
import Livechannelpartner from "./Pages/Live Stream/Management_Live_Stream/Livechannelpartner";
import ManageLiveCategories from "./Pages/Live Stream/Management_Live_Stream/ManageLiveCategories";
import AddManageLiveCategories from "./Pages/Live Stream/Management_Live_Stream/AddManageLiveCategories";
import EditManageLiveCategories from "./Pages/Live Stream/Management_Live_Stream/EditManageLiveCategories";
import LiveEventArtist from "./Pages/Live Stream/Management_Live_Stream/LiveEventArtist";
import AddLiveEventArtist from "./Pages/Live Stream/Management_Live_Stream/AddLiveEventArtist";
import EditLiveEventArtist from "./Pages/Live Stream/Management_Live_Stream/EditLiveEventArtist";
import SeriesList from "./Pages/Video/Series&Episode/SeriesList";
import Addnewseries from "./Pages/Video/Series&Episode/Addnewseries";
import ManageSeries from "./Pages/Video/Series&Episode/ManageSeries";
import AddManageSeries from "./Pages/Video/Series&Episode/AddManageSeries";
import EditManageseriesgenre from "./Pages/Video/Series&Episode/EditManageseriesgenre";
import SeriesApproval from "./Pages/Video/Series&Episode/SeriesApproval";
import Editseason from "./Pages/Video/Series&Episode/Editseason";
import EditSeriesList from "./Pages/Video/Series&Episode/EditSeriesList";
import EditEpisodemanage from "./Pages/Video/Series&Episode/EditEpisodemanage";
import EditEpisodeVideo from "./Pages/Video/Series&Episode/EditEpisodeVideo";
import Manageepisode from "./Pages/Video/Series&Episode/Manageepisode";
import SeriesContentPartner from "./Pages/Video/Series&Episode/SeriesContentPartner";
import SeriesChannelPartner from "./Pages/Video/Series&Episode/SeriesChannelPartner";

import AudioList from "./Pages/Audio/Audio_Management/AudioList";
import Addnewaudio from "./Pages/Audio/Audio_Management/Addnewaudio";
import AudioApproval from "./Pages/Audio/Audio_Management/AudioApproval";
import Audiocontentpartner from "./Pages/Audio/Audio_Management/Audiocontentpartner";
import Audiochannelpartner from "./Pages/Audio/Audio_Management/Audiochannelpartner";
import Editnewaudio from "./Pages/Audio/Audio_Management/Editnewaudio";
import Updatesong_Writer from "./Pages/Audio/Audio_Management/Updatesong_Writer";
import StoreSong_Writer from "./Pages/Audio/Audio_Management/StoreSong_Writer";
import Manageaudiocategories from "./Pages/Audio/Audio_Management/Manageaudiocategories";
import Managealbum from "./Pages/Audio/Audio_Management/Managealbum";
import AllArtist from "./Pages/Audio/Artist_Management/AllArtist";
import NewArtist from "./Pages/Audio/Artist_Management/NewArtist";
import EditNewArtist from "./Pages/Audio/Artist_Management/EditNewArtist";
import AllPublisher from "./Pages/Audio/Artist_Management/AllPublisher";
import AddPublisher from "./Pages/Audio/Artist_Management/AddPublisher";
import EditPublisher from "./Pages/Audio/Artist_Management/EditPublisher";
import AllPerformer from "./Pages/Audio/Artist_Management/AllPerformer";
import AddPerformer from "./Pages/Audio/Artist_Management/AddPerformer";
import EditPerformer from "./Pages/Audio/Artist_Management/EditPerformer";
import Addcategory from "./Pages/Audio/Audio_Management/Addcategory";
import Editcategory from "./Pages/Audio/Audio_Management/Editcategory";
import Addalbum from "./Pages/Audio/Audio_Management/Addalbum";
import Editalbum from "./Pages/Audio/Audio_Management/Editalbum";

import AllUser from "./Pages/Account/User/AllUser";
import AddUser from "./Pages/Account/User/AddUser";
import ImportUser from "./Pages/Account/User/ImportUser";
import MultiUserManagement from "./Pages/Account/User/MultiUserManagement";
import Menu from "./Pages/Account/Menu/Menu";
import AddMenu from "./Pages/Account/Menu/AddMenu";
import EditMenu from "./Pages/Account/Menu/EditMenu";
import FooterMenu from "./Pages/Account/Menu/FooterMenu";
import AddFooterMenu from "./Pages/Account/Menu/AddFooterMenu";
import EditFooterMenu from "./Pages/Account/Menu/EditFooterMenu";
import MobileMenu from "./Pages/Account/Menu/MobileMenu";
import AddMobileMenu from "./Pages/Account/Menu/AddMobileMenu";
import EditMobileMenu from "./Pages/Account/Menu/EditMobileMenu";
import SignupMenu from "./Pages/Account/SignupMenu/SignupMenu";
import FileManager from "./Pages/Account/FileManager/FileManager";
import Slider from "./Pages/Language/Slider/Slider";
import AddSlider from "./Pages/Language/Slider/AddSlider";
import EditSlider from "./Pages/Language/Slider/EditSlider";
import VideoLanguage from "./Pages/Language/Manage_Language/VideoLanguage";
import SubtitleLanguage from "./Pages/Language/Manage_Language/SubtitleLanguage";
import EditVideoLanguage from "./Pages/Language/Manage_Language/EditVideoLanguage";
import ManageTranslation from "./Pages/Language/Manage_Language/ManageTranslation";
import ManageTranslationLanguage from "./Pages/Language/Manage_Language/ManageTranslationLanguage";
import EditSubtitleLanguage from "./Pages/Language/Manage_Language/EditSubtitleLanguage";

import Player from "./Pages/Site/Player_UI/Player";

import AddContentPartner from "./Pages/Site/Content_Partner/AddContentPartner";
import ViewContentPartner from "./Pages/Site/Content_Partner/ViewContentPartner";
import EditContentPartner from "./Pages/Site/Content_Partner/EditContentPartner";
import ContentPartnerApproval from "./Pages/Site/Content_Partner/ContentPartnerApproval";
import ContentAddRole from "./Pages/Site/Content_Partner/ContentAddRole";
import ContentPartnerView from "./Pages/Site/Content_Partner/ContentPartnerView";
import EditContentPartnerView from "./Pages/Site/Content_Partner/EditContentPartnerView";
import ContentCommission from "./Pages/Site/Content_Partner/ContentCommission";
import ContentPayout from "./Pages/Site/Content_Partner/ContentPayout";

import ChannelPartnerApproval from "./Pages/Site/Channel_Partner/ChannelPartnerApproval";
import ChannelCommission from "./Pages/Site/Channel_Partner/ChannelCommission";
import ChannelPayout from "./Pages/Site/Channel_Partner/ChannelPayout";
import ChannelPackage from "./Pages/Site/Channel_Partner/ChannelPackage";
import AddChannelPackage from "./Pages/Site/Channel_Partner/AddChannelPackage";
import AllPages from "./Pages/Site/Site_Pages/AllPages";
import AddPages from "./Pages/Site/Site_Pages/AddPages";
import EditPages from "./Pages/Site/Site_Pages/EditPages";
// import CreateLandingPages from "./Pages/Site/Site_Pages/CreateLandingPages";
import LandingPages from "./Pages/Site/Site_Pages/LandingPages";
import AddLandingPages from "./Pages/Site/Site_Pages/AddLandingPages";
import EditLandingPages from "./Pages/Site/Site_Pages/EditLandingPages";
import SubscriptionPlan from "./Pages/Site/Plans/SubscriptionPlan";
import AddSubscriptionPlan from "./Pages/Site/Plans/AddSubscriptionPlan";
import EditSubscriptionPlan from "./Pages/Site/Plans/EditSubscriptionPlan";
import PurchasePlan from "./Pages/Site/Plans/PurchasePlan";
import EditPurchasePlan from "./Pages/Site/Plans/EditPurchasePlan";
import LifeTimeSubcription from "./Pages/Site/Plans/LifeTimeSubcription";
import Devices from "./Pages/Site/Plans/Devices";
import EditDevices from "./Pages/Site/Plans/EditDevices";
import TotalRevenue from "./Pages/Site/Payment_Management/TotalRevenue";
import SubcriptionPayment from "./Pages/Site/Payment_Management/SubcriptionPayment";
import ViewSubcription from "./Pages/Site/Payment_Management/ViewSubcription";
import PayperviewPayments from "./Pages/Site/Payment_Management/PayperviewPayments";
import ViewPayperview from "./Pages/Site/Payment_Management/ViewPayperview";
import UsersRevenue from "./Pages/Site/Analytics/UsersRevenue";
import UsersAnalytics from "./Pages/Site/Analytics/UsersAnalytics";
import PurchaseVideoAnalytics from "./Pages/Site/Analytics/PurchaseVideoAnalytics";
import CPPAnalytics from "./Pages/Site/Analytics/CPPAnalytics";
import CPPVideoAnalytics from "./Pages/Site/Analytics/CPPVideoAnalytics";
import CPPRevenue from "./Pages/Site/Analytics/CPPRevenue";
import ViewRegion from "./Pages/Site/Analytics/ViewRegion";
import RevenueRegion from "./Pages/Site/Analytics/RevenueRegion";
import PlayerVideoAnalytics from "./Pages/Site/Analytics/PlayerVideoAnalytics";
import RegionVideoAnalytics from "./Pages/Site/Analytics/RegionVideoAnalytics";
import PlayerUserAnalytics from "./Pages/Site/Analytics/PlayerUserAnalytics";
import CPPLiveVideo from "./Pages/Site/Analytics/CPPLiveVideo";
import LiveStreamAnalytics from "./Pages/Site/Analytics/LiveStreamAnalytics";
import StorefrontSettings from "./Pages/Site/Settings/StorefrontSettings";
import HomePageSettings from "./Pages/Site/Settings/HomePageSettings";
import Editorderhome from "./Pages/Site/Settings/Editorderhome";
import LinkSettings from "./Pages/Site/Settings/LinkSettings";
import ManageAge from "./Pages/Site/Settings/ManageAge";
import AddManageAge from "./Pages/Site/Settings/AddManageAge";
import EditManageAge from "./Pages/Site/Settings/EditManageAge";
import ThemeSettings from "./Pages/Site/Settings/ThemeSettings";
import EmailSettings from "./Pages/Site/Settings/EmailSettings";
import PaymentSettings from "./Pages/Site/Settings/PaymentSettings";
import Stripe from "./Pages/Site/Settings/PaymentSetting_All/Stripe";
import Paypal from "./Pages/Site/Settings/PaymentSetting_All/Paypal";
import Paystack from "./Pages/Site/Settings/PaymentSetting_All/Paystack";
import Razorpay from "./Pages/Site/Settings/PaymentSetting_All/Razorpay";
import Iyzico from "./Pages/Site/Settings/PaymentSetting_All/Iyzico";
import EmailSettingsTemplate from "./Pages/Site/Settings/EmailSettingsTemplate";
import StorageSettings from "./Pages/Site/Settings/StorageSettings";
import MobileSettings from "./Pages/Site/Settings/MobileSettings";
import EditSplashMobileSettings from "./Pages/Site/Settings/EditSplashMobileSettings";
import EditMobileSettings from "./Pages/Site/Settings/EditMobileSettings";
import SocialLoginSettings from "./Pages/Site/Settings/SocialLoginSettings";
import CurrencySettings from "./Pages/Site/Settings/CurrencySettings";
import RevenueSettings from "./Pages/Site/Settings/RevenueSettings";
import ThumbnailSettings from "./Pages/Site/Settings/ThumbnailSettings";
import ProfileScreen from "./Pages/Site/Settings/ProfileScreen";
import ViewChannelPartner from "./Pages/Site/Channel_Partner/ViewChannelPartner";
import Theme from "./Pages/Site/Settings/Theme";
import ImageSettings from "./Pages/Site/Settings/ImageSettings";
import PopupSettings from "./Pages/Site/Settings/PopupSettings";
import CommentSetting from "./Pages/Site/Settings/CommentSetting";
import Advertiser from "./Pages/Ads_Categories/Manage_Advertiser/Advertiser";
import EditAdvertiser from "./Pages/Ads_Categories/Manage_Advertiser/EditAdvertiser";
import EditAdsList from "./Pages/Ads_Categories/Ads_List/EditAdsList";
import AdsCategories from "./Pages/Ads_Categories/Ads_Categories/AdsCategories";
import EditAdsCategories from "./Pages/Ads_Categories/Ads_Categories/EditAdsCategories";
import AdsList from "./Pages/Ads_Categories/Ads_List/AdsList";
import AdsPlan from "./Pages/Ads_Categories/Ads_Plan/AdsPlan";
import EditAdsPlan from "./Pages/Ads_Categories/Ads_Plan/EditAdsPlan";
import AdsRevenue from "./Pages/Ads_Categories/Ads_Revenue/AdsRevenue";
import CalenderEvent from "./Pages/Ads_Categories/Calender_Event/CalenderEvent";
import AdsCampaigns from "./Pages/Ads_Categories/Ads_Campaigns/AdsCampaigns";
import AdsTimeSlot from "./Pages/Ads_Categories/Ads_Time_Slot/AdsTimeSlot";
import ManageGeoFencing from "./Pages/Geo_Fencing/Manage_Geo_Fencing/ManageGeoFencing";
import ManageCountry from "./Pages/Geo_Fencing/Manage_Country/ManageCountry";
import CacheManagement from "./Pages/Configuration/Cache_Management/CacheManagement";
import Debug from "./Pages/Configuration/Manage_Geo_Fencing/Debug";
import ContactRequest from "./Pages/ContactUs/Cache Management/ContactRequest";
import SeeLogActivity from "./Pages/LogActivity/Cache_Management/SeeLogActivity";
import Editvideo from "./Pages/Video/Video_Management/Editvideo";
import EditAddNewLiveStream from "./Pages/Live Stream/Management_Live_Stream/EditAddNewLiveStream";
import UpdateCurrencySettings from "./Pages/Site/Settings/UpdateCurrencySettings";
import ViewTemplate from "./Pages/Site/Settings/ViewTemplate";

import Rtmpurl from "./Pages/Site/Settings/Site_Setting/Rtmpurl";
import Site_Setting from "./Pages/Site/Settings/Site_Setting/Site_Setting";
import Ppv_Setting from "./Pages/Site/Settings/Site_Setting/Ppv_Setting";
import Video_Setting from "./Pages/Site/Settings/Site_Setting/Video_Setting";
import Registration_Setting from "./Pages/Site/Settings/Site_Setting/Registration_Setting";
import Email_Setting from "./Pages/Site/Settings/Site_Setting/Email_Setting";
import Social_Network_Setting from "./Pages/Site/Settings/Site_Setting/Social_Network_Setting";
import Series_Setting from "./Pages/Site/Settings/Site_Setting/Series_Setting";
import Transcoding_Setting from "./Pages/Site/Settings/Site_Setting/Transcoding_Setting";
import Coupon_Code_Setting from "./Pages/Site/Settings/Site_Setting/Coupon_Code_Setting";
import Loginpage_Setting from "./Pages/Site/Settings/Site_Setting/Loginpage_Setting";
import Advertisement_Setting from "./Pages/Site/Settings/Site_Setting/Advertisement_Setting";
import App_Setting from "./Pages/Site/Settings/Site_Setting/App_Setting";
import Script_Setting from "./Pages/Site/Settings/Site_Setting/Script_Setting";
import Default_Image_Setting from "./Pages/Site/Settings/Site_Setting/Default_Image_Setting";
import Payouts_Setting from "./Pages/Site/Settings/Site_Setting/Payouts_Setting";
import Recaptcha_Setting from "./Pages/Site/Settings/Site_Setting/Recaptcha_Setting";
import Timezone_Setting from "./Pages/Site/Settings/Site_Setting/Timezone_Setting";
import Manage_Geo_Fencing from "./Pages/Site/Settings/Site_Setting/Manage_Geo_Fencing";
import Site_met_settings from "./Pages/Site/Settings/Site_met_settings";
import Tv_Settings from "./Pages/Site/Settings/Tv_Settings";
import Update_Tv_Settings from "./Pages/Site/Settings/Update_Tv_Settings";

import AllVideos from "./Channel_Partner/VideoManagement/AllVideos";
import AddNewVideo from "./Channel_Partner/VideoManagement/AddNewVideo";
import ManageVideoCategories from "./Channel_Partner/VideoManagement/ManageVideoCategories";
// import Payouts from "./Channel_Partner/Analytics/Payouts";
import VideoAnalytics from "./Channel_Partner/Analytics/VideoAnalytics";
import VideoViewRegion from "./Channel_Partner/Analytics/VideoViewRegion";
import MyChannelSettings from "./Channel_Partner/SettingManage/MyChannelSettings";
import AllLiveVideo from "./Channel_Partner/ManageLiveVideo/AllLiveVideo";
import AddNewLiveVideo from "./Channel_Partner/ManageLiveVideo/AddNewLiveVideo";
import ManageLiveVideoCategories from "./Channel_Partner/ManageLiveVideo/ManageLiveVideoCategories";
import AddManageCategories from "./Channel_Partner/ManageLiveVideo/AddManageCategories";
import ManageLiveEvent from "./Channel_Partner/ManageLiveVideo/ManageLiveEvent";
import AddManageLiveEvent from "./Channel_Partner/ManageLiveVideo/AddManageLiveEvent";
import AudioLists from "./Channel_Partner/AudioManagement/AudioLists";
import ManageAudioCategories from "./Channel_Partner/AudioManagement/ManageAudioCategories";
import AddCategory from "./Channel_Partner/AudioManagement/AddCategory";
import ManageAlbums from "./Channel_Partner/AudioManagement/ManageAlbums";
import Addalbums from "./Channel_Partner/AudioManagement/Addalbums";
import SeriesLists from "./Channel_Partner/Series&Episode/SeriesLists";
import AddNewSeries from "./Channel_Partner/Series&Episode/AddNewSeries";
import AddManageVideoCategory from "./Channel_Partner/VideoManagement/AddManageVideoCategory";
import AddnewAudios from "./Channel_Partner/AudioManagement/AddnewAudios";
// import ChannelPage from "./Channel_Partner/ChannelPage";
import DashBoards from "./Channel_Partner/DashBoard/DashBoards";
import All_Videos from "./Content_Partner/VideoManagement/All_Videos";
import Add_NewVideos from "./Content_Partner/VideoManagement/Add_NewVideos";
import Manage_VideoCategories from "./Content_Partner/VideoManagement/Manage_VideoCategories";
import AddManage_VideoCategories from "./Content_Partner/VideoManagement/AddManage_VideoCategories";
import Audio_Lists from "./Content_Partner/AudioManagement/Audio_Lists";
import Add_NewAudios from "./Content_Partner/AudioManagement/Add_NewAudios";
import Manage_AudioCategories from "./Content_Partner/AudioManagement/Manage_AudioCategories";
import Manage_Albums from "./Content_Partner/AudioManagement/Manage_Albums";
import Add_category from "./Content_Partner/AudioManagement/Add_category";
import Add_Albums from "./Content_Partner/AudioManagement/Add_Albums";
import All_LiveVideo from "./Content_Partner/ManageLiveVideo/All_LiveVideo";
import Add_NewLiveVideo from "./Content_Partner/ManageLiveVideo/Add_NewLiveVideo";
import Manage_LiveCategory from "./Content_Partner/ManageLiveVideo/Manage_LiveCategory";
import Add_manageLiveCategory from "./Content_Partner/ManageLiveVideo/Add_manageLiveCategory";
import Manage_LiveEvent from "./Content_Partner/ManageLiveVideo/Manage_LiveEvent";
import Add_ManageLiveEvent from "./Content_Partner/ManageLiveVideo/Add_ManageLiveEvent";
import Video_Analytics from "./Content_Partner/Analytics/Video_Analytics";
import Video_ViewRegion from "./Content_Partner/Analytics/Video_ViewRegion";
import Video_Payout from "./Content_Partner/Analytics/Video_Payout";
import Live_StreamPayout from "./Content_Partner/Analytics/Live_StreamPayout";
import All_pages from "./Content_Partner/Pages/All_pages";
import Add_pages from "./Content_Partner/Pages/Add_pages";
import Dash_Board from "./Content_Partner/Dash_Board/Dash_Board";
import About_MyProfile from "./Content_Partner/Profile/About_MyProfile";

import Error404 from "./Error404/error404";
import Maintances from "./Error404/Maintances";
import Terms_and_condition from "./layout/Footer/Terms_and_condition";
import Privacy_policy from "./layout/Footer/Privacy_policy";
import AddChannelPartner from "./Pages/Site/Channel_Partner/AddChannelPartner";
import EditChannelPartner from "./Pages/Site/Channel_Partner/EditChannelPartner";
import EditChannelPackage from "./Pages/Site/Channel_Partner/EditChannelPackage";
import Update_Site_met_settings from "./Pages/Site/Settings/Update_Site_met_settings";
import Edit_Video_Playlist from "./Pages/Video/Video_Management/Edit_Video_Playlist";
import Video_Playlist from "./Pages/Video/Video_Management/Video_Playlist";
import AllVideo_Playlist from "./Pages/Video/Video_Management/AllVideo_Playlist";
import EditManageCalendarschedule from "./Pages/Video/Video_Management/EditManageCalendarschedule";
import Dsp_Settings from "./Pages/Site/Settings/Dsp_Settings";
import LoaderPage from "./components/LoaderPage";
import Search_Setting from "./Pages/Site/Settings/Site_Setting/Search_Setting";
import MusicSettings from "./Pages/Site/Music/MusicSettings";
import AudioSettings from "./Pages/Site/Settings/AudioSettings";
import AudioOrderEdit from "./Pages/Site/Settings/AudioOrderEdit";
import Main_labels from "./Pages/Site/Settings/Label_Settings/Main_labels";
import Video_label from "./Pages/Site/Settings/Label_Settings/Video_label";
import Series_labels from "./Pages/Site/Settings/Label_Settings/Series_labels";
import Livestream_labels from "./Pages/Site/Settings/Label_Settings/Livestream_labels";
import EditTranslationLanguage from "./Pages/Language/Manage_Language/EditTranslationLanguage";
import Audio_labels from "./Pages/Site/Settings/Label_Settings/Audio_labels";
import PrivateRoute from "./components/CommonComponents/PrivateRoute";
import ManageTranslationVer from "./Pages/Language/Manage_Language/ManageTranslationVer";

import FAQ from "./Pages/Faq/FAQ";
import Faq_category from "./Pages/Faq/Faq_category";
import Edit_FAQ from "./Pages/Faq/Edit_FAQ";
import Add_Faq_category from "./Pages/Faq/Add_Faq_category";
import Edit_Faq_category from "./Pages/Faq/Edit_Faq_category";
import EmaiLogs from "./Pages/Site/Settings/EmaiLogs";
import AdsUpload from "./Pages/Ads_Categories/Ads_Upload/AdsUpload";
import MovieSettings from "./Pages/Site/Settings/MovieSettings";
import ShowSettings from "./Pages/Site/Settings/ShowSettings";
import MovieOrderEdit from "./Pages/Site/Settings/MovieOrderEdit";
import ShowOrderEdit from "./Pages/Site/Settings/ShowOrderEdit";
import SubscriberAnalytics from "./Pages/Site/Analytics/SubscriberAnalytics";
import PPVAnalytics from "./Pages/Site/Analytics/PPVAnalytics";

function AllRoutes(props) {

  const [apiresponsealert, setApiresponsealert] = useState();
  const [showalert, setShowalert] = useState();
  const userData = localStorage.getItem("access_token");

  return (
    <>
      <Suspense fallback={<LoaderPage />}>
        <Routes>
          <Route path="/login" element={<Signin setApiresponsealert={setApiresponsealert} setShowalert={setShowalert} />} ></Route>
          {/* <Route path="/signup" element={<Signup setApiresponsealert={setApiresponsealert} setShowalert={setShowalert} />} ></Route> */}
          <Route path="/maintances" element={<Maintances setApiresponsealert={setApiresponsealert} setShowalert={setShowalert} ></Maintances>} ></Route>
          <Route path="/" element={userData ? <Layout apiresponsealert={apiresponsealert} showalert={showalert} setApiresponsealert={setApiresponsealert} setShowalert={setShowalert} ></Layout> : <Navigate to="/login" />}>
            <Route index element={<PrivateRoute><Dashboard setApiresponsealert={setApiresponsealert} setShowalert={setShowalert} ></Dashboard></PrivateRoute>} />

            {/* Video route start */}
            <Route path="/video-lists" element={<PrivateRoute><Allvideo></Allvideo></PrivateRoute>} ></Route>
            <Route path="/add-video" element={<PrivateRoute><Addnewvideo setApiresponsealert={setApiresponsealert} setShowalert={setShowalert} ></Addnewvideo></PrivateRoute>} ></Route>
            <Route path="/edit-video/:id" element={<PrivateRoute><Editvideo setApiresponsealert={setApiresponsealert} setShowalert={setShowalert}></Editvideo></PrivateRoute>} ></Route>
            {/* Video route end */}

            {/* Series route start */}
            <Route path="/series-list" element={<PrivateRoute><SeriesList setApiresponsealert={setApiresponsealert} setShowalert={setShowalert}></SeriesList></PrivateRoute>} ></Route>
            <Route path="/add-series" element={<PrivateRoute><Addnewseries setApiresponsealert={setApiresponsealert} setShowalert={setShowalert}></Addnewseries></PrivateRoute>} ></Route>
            <Route path="/edit-series/:id" element={<PrivateRoute><EditSeriesList setApiresponsealert={setApiresponsealert} setShowalert={setShowalert} ></EditSeriesList></PrivateRoute>} ></Route>
            {/* Series route end */}

            {/* Setting route start */}
            <Route path="/theme_settings" element={<PrivateRoute><ThemeSettings /></PrivateRoute>} />
            <Route ute path="/audio-settings" element={<PrivateRoute><AudioSettings /></PrivateRoute>} />
            <Route path="/edit-audio-order/:id" element={<PrivateRoute><AudioOrderEdit /></PrivateRoute>} />
            <Route ute path="/movie-settings" element={<PrivateRoute><MovieSettings /></PrivateRoute>} />
            <Route path="/edit-movie-order/:id" element={<PrivateRoute><MovieOrderEdit /></PrivateRoute>} />
            <Route ute path="/show-settings" element={<PrivateRoute><ShowSettings /></PrivateRoute>} />
            <Route path="/edit-show-order/:id" element={<PrivateRoute><ShowOrderEdit /></PrivateRoute>} />
            {/* Setting route end */}

            {/* Audio route start */}
            <Route path="/audio-lists" element={<PrivateRoute><AudioList /></PrivateRoute>} />
            <Route path="/add-audio" element={<PrivateRoute><Addnewaudio /></PrivateRoute>} />
            <Route path="/edit-audio/:id" element={<PrivateRoute><Editnewaudio /></PrivateRoute>} />
            <Route path="/add-song-writer-percentage-holding/:id" element={<PrivateRoute><StoreSong_Writer /></PrivateRoute>} />
            <Route path="/update-song-writer-percentage-holding/:id" element={<PrivateRoute><Updatesong_Writer /></PrivateRoute>} />
            <Route path="/audio-categories" element={<PrivateRoute><Manageaudiocategories /></PrivateRoute>} />
            <Route path="/add-audio-categories" element={<PrivateRoute><Addcategory /></PrivateRoute>} />
            <Route path="/edit-audio-categories/:id" element={<PrivateRoute><Editcategory /></PrivateRoute>} />
            <Route path="/audio-approval" element={<PrivateRoute><AudioApproval /></PrivateRoute>} ></Route>

            {/* Album start */}
            <Route path="/manage-albums" element={<PrivateRoute><Managealbum /></PrivateRoute>} ></Route>
            <Route path="/add-album" element={<PrivateRoute><Addalbum /></PrivateRoute>} ></Route>
            <Route path="/edit-album/:id" element={<PrivateRoute><Editalbum /></PrivateRoute>} ></Route>
            {/* Ablum end */}

            {/* Artist route start */}
            <Route path="/all-artist" element={<PrivateRoute><AllArtist /></PrivateRoute>} />
            <Route path="/add-artist" element={<PrivateRoute><NewArtist /></PrivateRoute>} />
            <Route path="/edit-artist/:id" element={<PrivateRoute><EditNewArtist /></PrivateRoute>} />
            <Route path="/artists-musician" element={<PrivateRoute><AllPerformer /></PrivateRoute>} />
            <Route path="/add-artist-musician" element={<PrivateRoute><AddPerformer /></PrivateRoute>} />
            <Route path="/edit-artist-musician/:id" element={<PrivateRoute><EditPerformer /></PrivateRoute>} />
            <Route path="/artists-songwriter" element={<PrivateRoute><AllPublisher /></PrivateRoute>} />
            <Route path="/add-artist-songwriter" element={<PrivateRoute><AddPublisher /></PrivateRoute>} />
            <Route path="/edit-artist-songwriter/:id" element={<PrivateRoute><EditPublisher /></PrivateRoute>} />

            {/* Artist route end */}

            {/* <Route path="/admin/channel" element={<PrivateRoute><ChannelPage></ChannelPage>}></Route> */}
            <Route path="/admin/channel/dashboard" element={<PrivateRoute><DashBoards setApiresponsealert={setApiresponsealert} setShowalert={setShowalert}  ></DashBoards></PrivateRoute>} ></Route>
            <Route path="/admin/channel/allvideos" element={<PrivateRoute><AllVideos setApiresponsealert={setApiresponsealert} setShowalert={setShowalert}></AllVideos></PrivateRoute>}></Route>

            {/* 
             //#region Ads Management
            */}
            <Route path="/ads-upload" element={<PrivateRoute> <AdsUpload /></PrivateRoute>} />
            <Route path="/ads-edit/:id" element={<PrivateRoute> <AdsUpload /></PrivateRoute>} />
            <Route path="/ads-list" element={<PrivateRoute> <AdsList /></PrivateRoute>} ></Route>


            {/* User Start */}
            <Route path="/users" element={<PrivateRoute><AllUser /></PrivateRoute>} ></Route>
            <Route path="/add-users" element={<PrivateRoute><AddUser /> </PrivateRoute>}></Route>
            <Route path="/edit-users/:id" element={<PrivateRoute> <AddUser /></PrivateRoute>} ></Route>
            {/* User End */}

            {/* Analytics start */}
            <Route path="/analytics/subscriber" element={<PrivateRoute><SubscriberAnalytics /></PrivateRoute>} ></Route>
            <Route path="/analytics/ppv" element={<PrivateRoute><PPVAnalytics /></PrivateRoute>} ></Route>
            {/* Analytics end */}

            <Route
              path="/admin/channel/add-new-videos"
              element={<PrivateRoute>
                <AddNewVideo
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddNewVideo>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/channel/video-categories"
              element={<PrivateRoute>
                <ManageVideoCategories
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ManageVideoCategories>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/channel/add-video-category"
              element={<PrivateRoute>
                <AddManageVideoCategory
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddManageVideoCategory>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/channel/series-lists"
              element={<PrivateRoute>
                <SeriesLists
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></SeriesLists>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/channel/add-new-series"
              element={<PrivateRoute>
                <AddNewSeries
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddNewSeries>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/channel/audio-lists"
              element={<PrivateRoute>
                <AudioLists
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AudioLists>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/channel/add-new-audio"
              element={<PrivateRoute>
                <AddnewAudios
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddnewAudios>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/channel/manage-audio-category"
              element={<PrivateRoute>
                <ManageAudioCategories
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ManageAudioCategories>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/channel/add-category"
              element={<PrivateRoute>
                <AddCategory
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddCategory>
              </PrivateRoute>}
            ></Route>

            <Route
              path="/admin/channel/add-albums"
              element={<PrivateRoute>
                <Addalbums
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Addalbums>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/channel/all-livevideos"
              element={<PrivateRoute>
                <AllLiveVideo
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AllLiveVideo>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/channel/add-newlive-video"
              element={<PrivateRoute>
                <AddNewLiveVideo
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddNewLiveVideo>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/channel/manage-live-category"
              element={<PrivateRoute>
                <ManageLiveVideoCategories
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ManageLiveVideoCategories>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/channel/add-manage-live-category"
              element={<PrivateRoute>
                <AddManageCategories
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddManageCategories>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/channel/manage-live-event"
              element={<PrivateRoute>
                <ManageLiveEvent
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ManageLiveEvent>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/channel/add-manage-live-event"
              element={<PrivateRoute>
                <AddManageLiveEvent
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddManageLiveEvent>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/channel/mychannel-settings"
              element={<PrivateRoute>
                <MyChannelSettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></MyChannelSettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/channel/video-analytics"
              element={<PrivateRoute>
                <VideoAnalytics
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></VideoAnalytics>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/channel/video-region"
              element={<PrivateRoute>
                <VideoViewRegion
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></VideoViewRegion>
              </PrivateRoute>}
            ></Route>
            {/* <Route
                path="/admin/channel/payouts"
                element={<PrivateRoute>
                  <Payouts
                    setApiresponsealert={setApiresponsealert}
                    setShowalert={setShowalert}
                  ></Payouts>
              </PrivateRoute>  }
              ></Route> */}

            {/* ContentPartner */}
            <Route
              path="/admin/content/dashboard"
              element={<PrivateRoute>
                <Dash_Board
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Dash_Board>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/all-videos"
              element={<PrivateRoute>
                <All_Videos
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></All_Videos>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/add-newvideos"
              element={<PrivateRoute>
                <Add_NewVideos
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Add_NewVideos>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/manage-video-category"
              element={<PrivateRoute>
                <Manage_VideoCategories
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Manage_VideoCategories>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/add-manage-video-category"
              element={<PrivateRoute>
                <AddManage_VideoCategories
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddManage_VideoCategories>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/audio-lists"
              element={<PrivateRoute>
                <Audio_Lists
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Audio_Lists>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/add-newaudio-lists"
              element={<PrivateRoute>
                <Add_NewAudios
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Add_NewAudios>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/manage-audio-category"
              element={<PrivateRoute>
                <Manage_AudioCategories
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Manage_AudioCategories>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/add-category"
              element={<PrivateRoute>
                <Add_category
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Add_category>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/manage-albums"
              element={<PrivateRoute>
                <Manage_Albums
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Manage_Albums>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/add-manage-albums"
              element={<PrivateRoute>
                <Add_Albums
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Add_Albums>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/all-livevideo"
              element={<PrivateRoute>
                <All_LiveVideo
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></All_LiveVideo>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/add-new-livevideo"
              element={<PrivateRoute>
                <Add_NewLiveVideo
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Add_NewLiveVideo>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/manage-live-videocategory"
              element={<PrivateRoute>
                <Manage_LiveCategory
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Manage_LiveCategory>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/add-manage-live-category"
              element={<PrivateRoute>
                <Add_manageLiveCategory
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Add_manageLiveCategory>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/manage-live-event"
              element={<PrivateRoute>
                <Manage_LiveEvent
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Manage_LiveEvent>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/add-manage-live-event"
              element={<PrivateRoute>
                <Add_ManageLiveEvent
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Add_ManageLiveEvent>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/video-analytics"
              element={<PrivateRoute>
                <Video_Analytics
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Video_Analytics>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/video-view-region"
              element={<PrivateRoute>
                <Video_ViewRegion
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Video_ViewRegion>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/video-payout"
              element={<PrivateRoute>
                <Video_Payout
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Video_Payout>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/live-stream-payout"
              element={<PrivateRoute>
                <Live_StreamPayout
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Live_StreamPayout>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/all-pages"
              element={<PrivateRoute>
                <All_pages
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></All_pages>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/add-pages"
              element={<PrivateRoute>
                <Add_pages
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Add_pages>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin/content/about-myprofile"
              element={<PrivateRoute>
                <About_MyProfile
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></About_MyProfile>
              </PrivateRoute>}
            ></Route>

            {/* video */}

            <Route
              path="/media-library"
              element={<PrivateRoute>
                <Media_Library
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Media_Library>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/view-library/:id"
              element={<PrivateRoute>
                <View_Media_Library
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></View_Media_Library>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/libraries"
              element={<PrivateRoute>
                <Main
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Main>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/content-video/:id"
              element={<PrivateRoute>
                <ContentVideo
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ContentVideo>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/content-live/:id"
              element={<PrivateRoute>
                <ContentLive
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ContentLive>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/content-audio/:id"
              element={<PrivateRoute>
                <ContentAudio
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ContentAudio>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/content-series/:id"
              element={<PrivateRoute>
                <ContentSeries
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ContentSeries>
              </PrivateRoute>}
            ></Route>

            <Route
              path="/add-playlist"
              element={<PrivateRoute>
                <Video_Playlist
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Video_Playlist>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-playlist/:id"
              element={<PrivateRoute>
                <Edit_Video_Playlist
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Edit_Video_Playlist>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/allplaylist"
              element={<PrivateRoute>
                <AllVideo_Playlist
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AllVideo_Playlist>
              </PrivateRoute>}
            ></Route>

            <Route
              path="/videos/editvideo/:id"
              element={<PrivateRoute>
                <EditVideotag
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditVideotag>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/video-for-approval"
              element={<PrivateRoute>
                <Videoforapproval
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Videoforapproval>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/video-channel-partner"
              element={<PrivateRoute>
                <Videochannelpartner
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Videochannelpartner>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/video-content-partner"
              element={<PrivateRoute>
                <Videocontentpartner
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Videocontentpartner>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/master-list"
              element={<PrivateRoute>
                <Mastervideolist
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Mastervideolist>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/video-schedule"
              element={<PrivateRoute>
                <Videoschedule
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Videoschedule>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/add-schedule"
              element={<PrivateRoute>
                <AddVideoschedule
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddVideoschedule>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-schedule/:id"
              element={<PrivateRoute>
                <EditVideoschedule
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditVideoschedule>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/manage/schedule/:id"
              element={<PrivateRoute>
                <EditManageCalendarschedule
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditManageCalendarschedule>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/manage-schedule/:id"
              element={<PrivateRoute>
                <Manageschedule
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Manageschedule>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/video-partner"
              element={<PrivateRoute>
                <Videopartner
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Videopartner>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/video-categories"
              element={<PrivateRoute>
                <Managevideocategories
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Managevideocategories>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/add-categories"
              element={<PrivateRoute>
                <AddManagevideocategories
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddManagevideocategories>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-categories/:id"
              element={<PrivateRoute>
                <EditManagevideocategories
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditManagevideocategories>
              </PrivateRoute>}
            ></Route>


            <Route
              path="/edit-episode/manage/:id"
              element={<PrivateRoute>
                <EditEpisodemanage
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditEpisodemanage>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-episode/video-manage/:id"
              element={<PrivateRoute>
                <EditEpisodeVideo
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditEpisodeVideo>
              </PrivateRoute>}
            ></Route>
            {/* <Route
                // path="/add-episode"
                element={<PrivateRoute><Addepisode></Addepisode></PrivateRoute>}
              ></Route> */}
            <Route
              path="/manage-episode/:seriesid/:id"
              element={<PrivateRoute>
                <Manageepisode
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Manageepisode>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-season/:id"
              element={<PrivateRoute>
                <Editseason
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Editseason>
              </PrivateRoute>}
            ></Route>

            <Route
              path="/Series-Genre"
              element={<PrivateRoute>
                <ManageSeries
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ManageSeries>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/add-Genre"
              element={<PrivateRoute>
                <AddManageSeries
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddManageSeries>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-genre/:id"
              element={<PrivateRoute>
                <EditManageseriesgenre
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditManageseriesgenre>
              </PrivateRoute>}
            ></Route>

            <Route
              path="/CPPSeriesIndex"
              element={<PrivateRoute>
                <SeriesApproval
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></SeriesApproval>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/series-content-partner"
              element={<PrivateRoute>
                <SeriesContentPartner
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></SeriesContentPartner>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/series-channel-partner"
              element={<PrivateRoute>
                <SeriesChannelPartner
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></SeriesChannelPartner>
              </PrivateRoute>}
            ></Route>
            {/* Live stream */}
            <Route
              path="/livestream"
              element={<PrivateRoute>
                <AllLiveStream
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AllLiveStream>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/add-livestream"
              element={<PrivateRoute>
                <AddNewLiveStream
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddNewLiveStream>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/CPPLiveVideosIndex"
              element={<PrivateRoute>
                <LiveStreamApproval
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></LiveStreamApproval>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/live-content-partner"
              element={<PrivateRoute>
                <Livecontentpartner
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Livecontentpartner>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/live-channel-partner"
              element={<PrivateRoute>
                <Livechannelpartner
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Livechannelpartner>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/livestream-categories"
              element={<PrivateRoute>
                <ManageLiveCategories
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ManageLiveCategories>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/livestream-add-categories"
              element={<PrivateRoute>
                <AddManageLiveCategories
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddManageLiveCategories>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/livestream-edit-categories/:id"
              element={<PrivateRoute>
                <EditManageLiveCategories
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditManageLiveCategories>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/live-event-artist"
              element={<PrivateRoute>
                <LiveEventArtist
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></LiveEventArtist>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/add-live-event-artist"
              element={<PrivateRoute>
                <AddLiveEventArtist
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddLiveEventArtist>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/live-event-artist-edit/:id"
              element={<PrivateRoute>
                <EditLiveEventArtist
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditLiveEventArtist>
              </PrivateRoute>}
            ></Route>

            <Route
              path="/edit/add-livestream/:id"
              element={<PrivateRoute>
                <EditAddNewLiveStream
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditAddNewLiveStream>
              </PrivateRoute>}
            ></Route>
            {/* Audio */}
            <Route
              path="/audio-content-partner"
              element={<PrivateRoute>
                <Audiocontentpartner
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Audiocontentpartner>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/audio-channel-partner"
              element={<PrivateRoute>
                <Audiochannelpartner
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Audiochannelpartner>
              </PrivateRoute>}
            ></Route>
            {/* Accounts */}

            <Route
              path="/import-users-view"
              element={<PrivateRoute>
                <ImportUser
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ImportUser>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/MultiUser-limit"
              element={<PrivateRoute>
                <MultiUserManagement
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></MultiUserManagement>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/menu"
              element={<PrivateRoute>
                <Menu
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Menu>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/addmenu"
              element={<PrivateRoute>
                <AddMenu
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddMenu>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/editmenu/:id"
              element={<PrivateRoute>
                <AddMenu
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddMenu>
              </PrivateRoute>}
            ></Route>
            {/* <Route
              path="/editmenu/:id"
              element={<PrivateRoute>
                <EditMenu
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditMenu>
              </PrivateRoute>}
            ></Route> */}
            <Route
              path="/footermenu"
              element={<PrivateRoute>
                <FooterMenu
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></FooterMenu>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/addfootermenu"
              element={<PrivateRoute>
                <AddFooterMenu
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddFooterMenu>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/editfootermenu/:id"
              element={<PrivateRoute>
                <EditFooterMenu
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditFooterMenu>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/mobilemenu"
              element={<PrivateRoute>
                <MobileMenu
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></MobileMenu>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/addmobilemenu"
              element={<PrivateRoute>
                <AddMobileMenu
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddMobileMenu>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/editmobilemenu/:id"
              element={<PrivateRoute>
                <EditMobileMenu
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditMobileMenu>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/signup-menu"
              element={<PrivateRoute>
                <SignupMenu
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></SignupMenu>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/filemanager"
              element={<PrivateRoute>
                <FileManager
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></FileManager>
              </PrivateRoute>}
            ></Route>
            {/* Languages */}
            <Route
              path="/admin-languages"
              element={<PrivateRoute>
                <VideoLanguage
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></VideoLanguage>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/admin-editlanguages/:id"
              element={<PrivateRoute>
                <EditVideoLanguage
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditVideoLanguage>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/translate-languages"
              element={<PrivateRoute>
                <ManageTranslation
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ManageTranslation>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-translate-language/:id"
              element={<PrivateRoute>
                <EditTranslationLanguage setApiresponsealert={setApiresponsealert} setShowalert={setShowalert}></EditTranslationLanguage>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/subtitlelanguage"
              element={<PrivateRoute>
                <SubtitleLanguage
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></SubtitleLanguage>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-subtitlelanguage/:id"
              element={<PrivateRoute>
                <EditSubtitleLanguage
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditSubtitleLanguage>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/translate-languages-index"
              element={<PrivateRoute>
                <ManageTranslationLanguage
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ManageTranslationLanguage>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/sliders"
              element={<PrivateRoute>
                <Slider
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Slider>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/addslider"
              element={<PrivateRoute>
                <AddSlider
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddSlider>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/editslider/:id"
              element={<PrivateRoute>
                <EditSlider
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditSlider>
              </PrivateRoute>}
            ></Route>
            {/* Site */}
            <Route
              path="/players"
              element={<PrivateRoute>
                <Player
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Player>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/moderator"
              element={<PrivateRoute>
                <AddContentPartner
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddContentPartner>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/allmoderator"
              element={<PrivateRoute>
                <ViewContentPartner
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ViewContentPartner>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-allmoderator/:id"
              element={<PrivateRoute>
                <EditContentPartner
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditContentPartner>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/approve-allmoderator/:id"
              element={<PrivateRoute>
                <EditContentPartner
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditContentPartner>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/allmoderator-approval"
              element={<PrivateRoute>
                <ContentPartnerApproval
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ContentPartnerApproval>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/moderator/role"
              element={<PrivateRoute>
                <ContentAddRole
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ContentAddRole>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/moderator/view"
              element={<PrivateRoute>
                <ContentPartnerView
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ContentPartnerView>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/moderator/view/edit/:id"
              element={<PrivateRoute>
                <EditContentPartnerView
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditContentPartnerView>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/moderator/commission"
              element={<PrivateRoute>
                <ContentCommission
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ContentCommission>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/moderator/payouts"
              element={<PrivateRoute>
                <ContentPayout
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ContentPayout>
              </PrivateRoute>}
            ></Route>
            {/* Ads Management */}
            <Route
              path="/channel/user/create"
              element={<PrivateRoute>
                <AddChannelPartner
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddChannelPartner>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/channel/view-channel-members"
              element={<PrivateRoute>
                <ViewChannelPartner
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ViewChannelPartner>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/channel/edit-view-channel-members/:id"
              element={<PrivateRoute>
                <EditChannelPartner
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditChannelPartner>
              </PrivateRoute>}
            ></Route>

            <Route
              path="/channel/partnerapproval"
              element={<PrivateRoute>
                <ChannelPartnerApproval
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ChannelPartnerApproval>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/channel/commission"
              element={<PrivateRoute>
                <ChannelCommission
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ChannelCommission>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/channel/payouts"
              element={<PrivateRoute>
                <ChannelPayout
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ChannelPayout>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/channel-package-index"
              element={<PrivateRoute>
                <ChannelPackage
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ChannelPackage>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/add-channel-package-index"
              element={<PrivateRoute>
                <AddChannelPackage
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddChannelPackage>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-channel-package-index/:id"
              element={<PrivateRoute>
                <EditChannelPackage
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditChannelPackage>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/pages"
              element={<PrivateRoute>
                <AllPages
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AllPages>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/addpages"
              element={<PrivateRoute>
                <AddPages
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddPages>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/editpages/:id"
              element={<PrivateRoute>
                <EditPages
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditPages>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/landing-page/index"
              element={<PrivateRoute>
                <LandingPages
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></LandingPages>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/add-landing-page/index"
              element={<PrivateRoute>
                <AddLandingPages
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddLandingPages>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-landing-page/:id"
              element={<PrivateRoute>
                <EditLandingPages
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditLandingPages>
              </PrivateRoute>}
            ></Route>
            {/* <Route
                path="/landing-page/create"
                element={<PrivateRoute><CreateLandingPages setApiresponsealert={setApiresponsealert}
              setShowalert={setShowalert}></CreateLandingPages>}
             </PrivateRoute> ></Route> */}
            <Route
              path="/subscription-plans"
              element={<PrivateRoute>
                <SubscriptionPlan
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></SubscriptionPlan>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/add-subscription-plans"
              element={<PrivateRoute>
                <AddSubscriptionPlan
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddSubscriptionPlan>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-subscription-plans/:id"
              element={<PrivateRoute>
                <EditSubscriptionPlan
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditSubscriptionPlan>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/inapp-purchase"
              element={<PrivateRoute>
                <PurchasePlan
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></PurchasePlan>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-inapp-purchase/:id"
              element={<PrivateRoute>
                <EditPurchasePlan
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditPurchasePlan>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/Life-time-subscription"
              element={<PrivateRoute>
                <LifeTimeSubcription
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></LifeTimeSubcription>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/devices"
              element={<PrivateRoute>
                <Devices
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Devices>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-devices/:id"
              element={<PrivateRoute>
                <EditDevices
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditDevices>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/payment/total_revenue"
              element={<PrivateRoute>
                <TotalRevenue
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></TotalRevenue>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/payment/subscription"
              element={<PrivateRoute>
                <SubcriptionPayment
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></SubcriptionPayment>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/payment/subscription-view/:id"
              element={<PrivateRoute>
                <ViewSubcription
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ViewSubcription>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/payment/payPerView"
              element={<PrivateRoute>
                <PayperviewPayments
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></PayperviewPayments>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/payment/payPerView-view/:id"
              element={<PrivateRoute>
                <ViewPayperview
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ViewPayperview>
              </PrivateRoute>}
            ></Route>

            <Route
              path="/analytics/users"
              element={<PrivateRoute>
                <UsersAnalytics
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></UsersAnalytics>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/users/revenue"
              element={<PrivateRoute>
                <UsersRevenue
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></UsersRevenue>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/video/purchased-analytics"
              element={<PrivateRoute>
                <PurchaseVideoAnalytics
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></PurchaseVideoAnalytics>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/cpp/analytics"
              element={<PrivateRoute>
                <CPPAnalytics
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></CPPAnalytics>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/cpp/video-analytics"
              element={<PrivateRoute>
                <CPPVideoAnalytics
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></CPPVideoAnalytics>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/cpp/revenue"
              element={<PrivateRoute>
                <CPPRevenue
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></CPPRevenue>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/analytics/ViewsRegion"
              element={<PrivateRoute>
                <ViewRegion
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ViewRegion>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/analytics/RevenueRegion"
              element={<PrivateRoute>
                <RevenueRegion
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></RevenueRegion>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/analytics/PlayerVideoAnalytics"
              element={<PrivateRoute>
                <PlayerVideoAnalytics
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></PlayerVideoAnalytics>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/analytics/RegionVideoAnalytics"
              element={<PrivateRoute>
                <RegionVideoAnalytics
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></RegionVideoAnalytics>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/analytics/PlayerUserAnalytics"
              element={<PrivateRoute>
                <PlayerUserAnalytics
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></PlayerUserAnalytics>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/analytics/livestream-analytics"
              element={<PrivateRoute>
                <CPPLiveVideo
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></CPPLiveVideo>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/live/purchased-analytics"
              element={<PrivateRoute>
                <LiveStreamAnalytics
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></LiveStreamAnalytics>
              </PrivateRoute>}
            ></Route>

            <Route
              path="/settings"
              element={<PrivateRoute>
                <StorefrontSettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></StorefrontSettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/faq"
              element={<PrivateRoute>
                <FAQ
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></FAQ>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-faq/:id"
              element={<PrivateRoute>
                <Edit_FAQ
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Edit_FAQ>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/faq-category"
              element={<PrivateRoute>
                <Faq_category
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Faq_category>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/add-faq-category"
              element={<PrivateRoute>
                <Add_Faq_category
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Add_Faq_category>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-faq-category/:id"
              element={<PrivateRoute>
                <Edit_Faq_category
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Edit_Faq_category>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/labels"
              element={<PrivateRoute>
                <Main_labels
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Main_labels>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/label/videos"
              element={<PrivateRoute>
                <Video_label
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Video_label>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/label/series"
              element={<PrivateRoute>
                <Series_labels
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Series_labels>
              </PrivateRoute>}
            ></Route>

            <Route
              path="/label/live-stream"
              element={<PrivateRoute>
                <Livestream_labels
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Livestream_labels>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/label/audio"
              element={<PrivateRoute>
                <Audio_labels
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Audio_labels>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/rtmpurl"
              element={<PrivateRoute>
                <Rtmpurl
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Rtmpurl>
              </PrivateRoute>}
            ></Route>

            <Route
              path="/site/site-setting"
              element={<PrivateRoute>
                <Site_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Site_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/ppv-setting"
              element={<PrivateRoute>
                <Ppv_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Ppv_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/video-setting"
              element={<PrivateRoute>
                <Video_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Video_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/search-setting"
              element={<PrivateRoute>
                <Search_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Search_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/registration-setting"
              element={<PrivateRoute>
                <Registration_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Registration_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/email-setting"
              element={<PrivateRoute>
                <Email_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Email_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/social-network-setting"
              element={<PrivateRoute>
                <Social_Network_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Social_Network_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/series-setting"
              element={<PrivateRoute>
                <Series_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Series_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/transcoding-setting"
              element={<PrivateRoute>
                <Transcoding_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Transcoding_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/coupon-code-setting"
              element={<PrivateRoute>
                <Coupon_Code_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Coupon_Code_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/loginpage-setting"
              element={<PrivateRoute>
                <Loginpage_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Loginpage_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/advertisement-setting"
              element={<PrivateRoute>
                <Advertisement_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Advertisement_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/app-setting"
              element={<PrivateRoute>
                <App_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></App_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/script-setting"
              element={<PrivateRoute>
                <Script_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Script_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/default-image-setting"
              element={<PrivateRoute>
                <Default_Image_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Default_Image_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/payouts-setting"
              element={<PrivateRoute>
                <Payouts_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Payouts_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/recaptcha-setting"
              element={<PrivateRoute>
                <Recaptcha_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Recaptcha_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site/timezone-setting"
              element={<PrivateRoute>
                <Timezone_Setting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Timezone_Setting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/geo-fencing-setting"
              element={<PrivateRoute>
                <Manage_Geo_Fencing
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Manage_Geo_Fencing>
              </PrivateRoute>}
            ></Route>

            <Route
              path="/home-settings"
              element={<PrivateRoute>
                <HomePageSettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></HomePageSettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-orderhome/:id"
              element={<PrivateRoute>
                <Editorderhome
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Editorderhome>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/linking_settings"
              element={<PrivateRoute>
                <LinkSettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></LinkSettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/age/index"
              element={<PrivateRoute>
                <ManageAge
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ManageAge>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/add-age"
              element={<PrivateRoute>
                <AddManageAge
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AddManageAge>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit/manage-age/:id"
              element={<PrivateRoute>
                <EditManageAge
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditManageAge>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/email_settings"
              element={<PrivateRoute>
                <EmailSettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EmailSettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/email-logs"
              element={<PrivateRoute>
                <EmaiLogs
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EmaiLogs>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/payment_settings"
              element={<PrivateRoute>
                <PaymentSettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></PaymentSettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/stripe_settings"
              element={<PrivateRoute>
                <Stripe
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Stripe>
              </PrivateRoute>}

            ></Route>
            <Route
              path="/Iyzico_settings"
              element={<PrivateRoute>
                <Iyzico
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Iyzico>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/razorpay_settings"
              element={<PrivateRoute>
                <Razorpay
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Razorpay>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/paypal_settings"
              element={<PrivateRoute>
                <Paypal
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Paypal>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/paystack_settings"
              element={<PrivateRoute>
                <Paystack
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Paystack>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-template-email/:id"
              element={<PrivateRoute>
                <EmailSettingsTemplate
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EmailSettingsTemplate>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/template-email/view/:id"
              element={<PrivateRoute>
                <ViewTemplate
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ViewTemplate>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/storage_settings"
              element={<PrivateRoute>
                <StorageSettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></StorageSettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/mobileapp"
              element={<PrivateRoute>
                <MobileSettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></MobileSettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/mobileapp/splash/edit/:id"
              element={<PrivateRoute>
                <EditSplashMobileSettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditSplashMobileSettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/mobileapp/edit/:id"
              element={<PrivateRoute>
                <EditMobileSettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditMobileSettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/system_settings"
              element={<PrivateRoute>
                <SocialLoginSettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></SocialLoginSettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit_currency_settings"
              element={<PrivateRoute>
                <CurrencySettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></CurrencySettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/currency_settings"
              element={<PrivateRoute>
                <CurrencySettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></CurrencySettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/update_currency_settings/:id"
              element={<PrivateRoute>
                <UpdateCurrencySettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></UpdateCurrencySettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/revenue_settings/index"
              element={<PrivateRoute>
                <RevenueSettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></RevenueSettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/ThumbnailSetting"
              element={<PrivateRoute>
                <ThumbnailSettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ThumbnailSettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/ChooseProfileScreen"
              element={<PrivateRoute>
                <ProfileScreen
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ProfileScreen>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/ThemeIntegration"
              element={<PrivateRoute>
                <Theme
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Theme>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/compress-image-setting"
              element={<PrivateRoute>
                <ImageSettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ImageSettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/pop-up-setting"
              element={<PrivateRoute>
                <PopupSettings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></PopupSettings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/comment-section-setting"
              element={<PrivateRoute>
                <CommentSetting
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></CommentSetting>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/site-meta-settings"
              element={<PrivateRoute>
                <Site_met_settings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Site_met_settings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/update_sitemeta_settings/:id"
              element={<PrivateRoute>
                <Update_Site_met_settings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Update_Site_met_settings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/tv-settings"
              element={<PrivateRoute>
                <Tv_Settings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Tv_Settings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/update_tvsetting/:id"
              element={<PrivateRoute>
                <Update_Tv_Settings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Update_Tv_Settings>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/dsp-settings"
              element={<PrivateRoute>
                <Dsp_Settings
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Dsp_Settings>
              </PrivateRoute>}
            ></Route>

            <Route
              path="/advertisers"
              element={<PrivateRoute>
                <Advertiser
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Advertiser>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/editadvertiser/:id"
              element={<PrivateRoute>
                <EditAdvertiser
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditAdvertiser>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit_ads_list/:id"
              element={<PrivateRoute>
                <EditAdsList
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditAdsList>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/ads_categories"
              element={<PrivateRoute>
                <AdsCategories
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AdsCategories>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit_ads_categories/:id"
              element={<PrivateRoute>
                <EditAdsCategories
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditAdsCategories>
              </PrivateRoute>}
            ></Route>

            <Route
              path="/ads_plans"
              element={<PrivateRoute>
                <AdsPlan
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AdsPlan>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/edit-adsplan/:id"
              element={<PrivateRoute>
                <EditAdsPlan
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></EditAdsPlan>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/ads_revenue"
              element={<PrivateRoute>
                <AdsRevenue
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AdsRevenue>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/calender"
              element={<PrivateRoute>
                <CalenderEvent
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></CalenderEvent>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/ads_campaigns"
              element={<PrivateRoute>
                <AdsCampaigns
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AdsCampaigns>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/ads-time-slot"
              element={<PrivateRoute>
                <AdsTimeSlot
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></AdsTimeSlot>
              </PrivateRoute>}
            ></Route>

            {/* Geo_Fencing */}
            <Route
              path="/Geofencing"
              element={<PrivateRoute>
                <ManageGeoFencing
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ManageGeoFencing>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/countries"
              element={<PrivateRoute>
                <ManageCountry
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ManageCountry>
              </PrivateRoute>}
            ></Route>
            {/* Configuration */}
            <Route
              path="/clear_cache"
              element={<PrivateRoute>
                <CacheManagement
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></CacheManagement>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/debug"
              element={<PrivateRoute>
                <Debug
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Debug>
              </PrivateRoute>}
            ></Route>
            {/* Contact Us */}
            <Route
              path="/contact-us"
              element={<PrivateRoute>
                <ContactRequest
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ContactRequest>
              </PrivateRoute>}
            ></Route>
            {/* Log Activity */}
            <Route
              path="/logActivity"
              element={<PrivateRoute>
                <SeeLogActivity
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></SeeLogActivity>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/terms-and-conditions"
              element={<PrivateRoute>
                <Terms_and_condition
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Terms_and_condition>
              </PrivateRoute>}
            ></Route>
            <Route
              path="/privacy-policy"
              element={<PrivateRoute>
                <Privacy_policy
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></Privacy_policy>
              </PrivateRoute>}
            ></Route>
            <Route path="/music-settings" element={<PrivateRoute><MusicSettings /></PrivateRoute>} />
            <Route path="*" element={<Error404></Error404>}></Route>
            <Route
              path="/translate-index"
              element={<PrivateRoute>
                <ManageTranslationVer
                  setApiresponsealert={setApiresponsealert}
                  setShowalert={setShowalert}
                ></ManageTranslationVer>
              </PrivateRoute>}
            ></Route>
          </Route>
        </Routes>
      </Suspense>

    </>
  );
}

export default AllRoutes;
